import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './screen/Homepage';
import About from './screen/About';
import Services from './screen/Services';
import Contact from './screen/Contact';


function App() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* Add more routes here as needed */}
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    );
  }

export default App;
